import React, { FC, useState, useEffect, useContext } from "react";
import {
  Notification,
  NotificationPriority,
  AllNotificationAction,
  NotificationIntent
} from "types/Notification";
import Toast, { ToastContext } from "./Toast";

import "./toast.css";
import produce from "immer";
import classNames from "classnames";
import { getNavigationUrlFromMap } from "utils/navigation.utils";
import { Link } from "react-router-dom";
import {
  ProcessusDefinitionNature,
  ProcessusAffectation,
  ProcessusDefinition
} from "types/Processus";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import ProcessusLink from "composants/processus/ProcessusLink";
import { useDispatch } from "react-redux";

const NOTIFICATION_PRIORITY_OVERLAY_DURATION: Record<NotificationPriority, number> = {
  TEMPORARY: 5,
  LOW: 5,
  NORMAL: 10,
  HIGH: 30,
  CRITICAL: 60
};

const NOTIFICATION_DIRECTION = ["TEMPORARY", "LOW", "NORMAL", "HIGH", "CRITICAL"] as const;
const NOTIFICATION_MIN_DIRECTION_FOR_ACTION = 3;

const ToastManager: FC<{
  bindNotify(handler: Function): void;
  bindCloseAll(handler: Function): void;
}> = ({ bindNotify, bindCloseAll }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    bindNotify(notify);
  }, [bindNotify]);

  useEffect(() => {
    bindCloseAll(closeAll);
  }, [bindCloseAll]);

  function notify(notification: Notification) {
    setNotifications(old => {
      const index = old.findIndex(n => n.id === notification.id);
      // sur le lapse de temps d'ouverture d'un message,
      // on update le message courant si on retombe sur le même message en ajout
      // pour éviter les duplicats.
      // ça ne pose pas trop de problème ici, par que le message est bien supprimé d'ici une fois que la notification part.
      const sameMessageIndex = old.findIndex(n => n.title === notification.title);

      if (index !== -1 || sameMessageIndex !== -1) {
        const currentIndex = index !== -1 ? index : sameMessageIndex;
        return produce(old, draft => {
          draft[currentIndex] = notification;
        });
      }
      return [notification, ...old];
    });
  }

  function closeAll() {
    setNotifications([]);
  }

  function filterNotification(id: string) {
    setNotifications(old => old.filter(notif => notif.id !== id));
  }

  return (
    <span className="toast-container">
      <div className="flex flex-col">
        {notifications.map(notif => {
          const shouldRemainLonger = (notif.actions?.length ?? 0) > 0;

          let duration = NOTIFICATION_PRIORITY_OVERLAY_DURATION[notif.priority];

          if (shouldRemainLonger) {
            const durationDirectionIndex = NOTIFICATION_DIRECTION.indexOf(notif.priority);

            if (durationDirectionIndex < NOTIFICATION_MIN_DIRECTION_FOR_ACTION) {
              duration =
                NOTIFICATION_PRIORITY_OVERLAY_DURATION[
                  NOTIFICATION_DIRECTION[NOTIFICATION_MIN_DIRECTION_FOR_ACTION]
                ];
            }
          }

          return (
            <Toast
              key={notif.id}
              intent={notif.intent}
              title={notif.title}
              onRemove={() => filterNotification(notif.id)}
              duration={duration}
            >
              {notif.message}
              {notif.actions ? (
                <div>
                  {notif.actions.map((action, i) => (
                    <ToastButton key={i} action={action} intent={notif.intent} />
                  ))}
                </div>
              ) : null}
            </Toast>
          );
        })}
      </div>
    </span>
  );
};

function ToastButton({
  action,
  intent
}: {
  action: AllNotificationAction;
  intent: NotificationIntent;
}) {
  const dispatch = useDispatch();
  const close = useContext(ToastContext);
  const classes = classNames("button is-small is-rounded ", {
    "is-link is-outlined": intent === "INFO",
    "is-success is-outlined": intent === "SUCCESS",
    "is-warning": intent === "WARNING",
    "is-danger is-outlined": intent === "DANGER"
  });
  const styles: React.CSSProperties = {
    borderStyle: "dashed"
  };

  if (action.type === "LINK") {
    const url = getNavigationUrlFromMap(action.params);
    return (
      <Link className={classes} to={url} onClick={close} style={styles}>
        {action.label}
      </Link>
    );
  } else if (action.type === "DIRECT_LINK") {
    return (
      <Link className={classes} to={action.url} onClick={close} style={styles}>
        {action.label}
      </Link>
    );
  } else if (action.type === "PROCESSUS") {
    const {
      apercu = false,
      entities,
      label,
      nature,
      processId,
      rapide = false,
      sjmoCode,
      tableName
    } = action.params;

    let type: ProcessusDefinitionNature;
    switch (nature) {
      case "EDITA":
      case "EDITS":
        type = "edition";
        break;
      case "JAVA":
      case "TRAIT":
        type = "traitement";
        break;
      case "NAEXT":
      case "NAINT":
        type = "navigation";
        break;
    }

    const definition = {
      id: processId,
      type: type,
      label: label,
      affectation: ProcessusAffectation.GLOBAL,
      needEntity: true,
      apercu: type === "edition" ? apercu : undefined,
      rapide: type === "edition" ? rapide : undefined,
      forAll: type === "traitement" ? false : undefined,
      isAdvanced: type === "traitement" ? false : undefined,
      isOneByOne: false
    } as ProcessusDefinition;

    return (
      <ProcessusProvider
        selected={entities}
        tableName={tableName}
        sjmoCode={sjmoCode}
        onAfterSaveProcess={close}
      >
        <ProcessusLink className={classes} definition={definition} style={styles} />
      </ProcessusProvider>
    );
  } else if (action.type === "DISPATCH") {
    const { type, ...payload } = action.params;
    return (
      <button
        className={classes}
        style={styles}
        onClick={() => {
          dispatch({ type, payload });
          // impossible de savoir si la notification est OK
          // on lance la note alors comme validé.
          // on ne désactive pas le bouton après validation justement au cas où la personne souhaite encore
          // utiliser le bouton s'il y a eu une erreur lors du lancement du dispatch.
          close();
        }}
      >
        {action.label}
      </button>
    );
  } else {
    return null;
  }
}

export default ToastManager;
